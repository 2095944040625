import React, { useState, useContext, Fragment } from 'react';
import {
  AutocompleteInput,
  useEditController,
  Create,
  Datagrid,
  DateField,
  EditButton,
  List,
  useShowController,
  ReferenceField,
  ReferenceInput,
  required,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  SaveButton,
  Toolbar,
  useRedirect,
  ListButton,
} from 'react-admin';
import { AmplifyFilter } from 'react-admin-amplify';
import Card from '@material-ui/core/Card';
import AlertError from '@material-ui/icons/ErrorOutline';
import { Button } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { Stepper } from 'react-form-stepper';
import styled from 'styled-components';
import { color, typography, space, layout, flexbox } from 'styled-system';
import Marquee from 'react-fast-marquee';
import UUID from 'uuidjs';
import { loginState } from '../App';
import DeleteWithCustomConfirmButton from 'ra-delete-with-custom-confirm-button';
import { DeleteConfirmTitle, DeleteConfirmContent } from './DeleteConfirm';
import Manual from './GifPlayer';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdContentCopy } from 'react-icons/md';

const domain =
  process.env.REACT_APP_TRANS_BOT_DOMAIN ??
  process.env.REACT_APP_TRANS_BOT_DEFAULT_DOMAIN;
const yaml = require('js-yaml');

const defaultQuery = 'listSlackBots';
const Banner = styled(Marquee)`
  font-size: 1rem;
  color: palevioletred;
  padding: 1.5rem;
`;
const Message = styled.span`
  padding-right: 10rem;
`;
const ClipbordBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  margin: 1rem;
  background: #afb3b8;
  padding: 0.1rem;
  position: relative;
  width: 80%;
`;

const ClipbordBoxText = styled.pre`
  margin: 0;
  padding: 1em;
  font-size: 80%;
  white-space: pre-wrap;
`;

const ClipbordBoxMsg = styled.span`
  position: absolute;
  right: 1%;
  top: 5%;
  bottom: 0;
  font-size: 150%;
`;

const StyledCard = styled(Card)`
  ${color}
  ${typography}
  ${space}
  ${layout}
  ${flexbox}
`;
const StyledToolbar = styled(Toolbar)`
  ${color}
  ${typography}
  ${space}
  ${layout}
  ${flexbox}
`;
const PostBulkActionButtons = () => <Fragment></Fragment>;
const BotFilter = (props) => (
  <AmplifyFilter {...props} defaultQuery={defaultQuery}>
    <TextInput source="slackBotByName.name" label="Name" alwaysOn resettable />
  </AmplifyFilter>
);
const generateManifest = (props) => {
  let bot = [];
  let bot_events = [];
  let url = '';
  if (props.botKind === 'slack_app_channel') {
    bot = ['chat:write', 'channels:manage', 'channels:history'];
    bot_events = ['message.channels'];
    url = `https://${props.domain}/trans/slack/app/chat/${props.user}?bot=${props.bot}`;
  } else if (props.botKind === 'slack_app_dm') {
    bot = ['im:write', 'im:history', 'chat:write'];
    bot_events = ['message.im'];
    url = `https://${props.domain}/trans/slack/app/im/${props.user}?bot=${props.bot}`;
  }
  const slackManifest = {
    _metadata: {
      major_version: 1,
      minor_version: 1,
    },
    display_information: {
      name: props.botName,
    },
    features: {
      app_home: {
        home_tab_enabled: true,
        messages_tab_enabled: true,
        messages_tab_read_only_enabled: false,
      },
      bot_user: {
        display_name: props.botName,
        always_online: true,
      },
    },
    oauth_config: {
      scopes: {
        bot,
      },
    },
    settings: {
      event_subscriptions: {
        request_url: url,
        bot_events,
      },
      org_deploy_enabled: false,
      socket_mode_enabled: false,
      token_rotation_enabled: false,
    },
  };
  return yaml.dump(slackManifest);
};

export const SlackBotList = (props) => {
  const [query, setQuery] = useState(defaultQuery);
  const { state } = useContext(loginState);

  return (
    <List
      {...props}
      exporter={false}
      filters={<BotFilter setQuery={setQuery} />}
      bulkActionButtons={<PostBulkActionButtons />}
    >
      <Datagrid>
        <TextField
          source="id"
          label="ID"
          sortBy={query}
          sortable={query === 'SlackBotByName'}
        />
        <TextField source="name" label="ボット名" sortable={false} />
        {state.group === 'admin' ? (
          <TextField source="owner" sortable={false} />
        ) : null}
        <ReferenceField
          source="kindID"
          reference="slackBotKinds"
          label="種類"
          link="false"
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" label="作成日時" sortable={false} />
        <DateField source="updatedAt" label="更新日時" sortable={false} />
        <ShowButton />
        <EditButton />
        <DeleteWithCustomConfirmButton
          title={DeleteConfirmTitle}
          content={DeleteConfirmContent}
        />
      </Datagrid>
    </List>
  );
};

export const SlackBotShow = (props) => {
  const ShowToolbar = (props) => {
    return (
      <StyledToolbar {...props} display="flex" justifyContent="space-between">
        <ListButton basePath={basePath} label="Cancel" icon={<AlertError />} />
      </StyledToolbar>
    );
  };
  const { resource, record, redirect, save, basePath, version } =
    useShowController(props);

  return (
    <div>
      <StyledCard width={1000} mt="1em">
        {record && (
          <SimpleForm
            basePath={basePath}
            redirect={redirect}
            resource={resource}
            record={record}
            save={save}
            toolbar={<ShowToolbar />}
            version={version}
          >
            <TextField source="id" label="ID" fullWidth />
            <TextField source="name" label="ボット名" fullWidth />
            <ReferenceField
              source="kindID"
              reference="slackBotKinds"
              label="種類"
              link="false"
            >
              <TextField source="name" fullWidth />
            </ReferenceField>
            <TextField source="bot_token" label="ボットトークン" fullWidth />
            <TextField
              source="signing_secret"
              label="サインインシークレット"
              fullWidth
            />
            <TextField
              source="manifest"
              label="マニフェスト"
              component="pre"
              fullWidth
            />
            <DateField source="createdAt" label="作成日時" fullWidth />
            <DateField source="updatedAt" label="更新日時" fullWidth />
          </SimpleForm>
        )}
      </StyledCard>
    </div>
  );
};

export const SlackBotEdit = (props) => {
  const EditToolbar = (props) => {
    return (
      <StyledToolbar {...props} display="flex" justifyContent="space-between">
        <ListButton basePath={basePath} label="Cancel" icon={<AlertError />} />
        <SaveButton redirect="list" />
      </StyledToolbar>
    );
  };
  const { resource, record, redirect, save, basePath, version } =
    useEditController(props);

  return (
    <div>
      <StyledCard width={1000} mt="1em">
        {record && (
          <SimpleForm
            basePath={basePath}
            redirect={redirect}
            resource={resource}
            record={record}
            save={save}
            toolbar={<EditToolbar />}
            version={version}
          >
            <TextInput source="id" label="ID" disabled fullWidth />
            <TextInput source="name" label="ボット名" disabled fullWidth />
            <TextInput source="owner" label="オーナー" disabled fullWidth />
            <ReferenceInput
              source="kindID"
              disabled
              reference="slackBotKinds"
              label="種類"
              link="false"
              filterToQuery={(searchText) => ({
                slackBotByName: { name: searchText },
              })}
            >
              <AutocompleteInput optionText="name" fullWidth />
            </ReferenceInput>
            <TextInput source="bot_token" label="ボットトークン" fullWidth />
            <TextInput
              source="signing_secret"
              label="サインインシークレット"
              fullWidth
            />
            <TextInput
              source="manifest"
              label="マニフェスト"
              disabled
              fullWidth
              multiline
            />
          </SimpleForm>
        )}
      </StyledCard>
    </div>
  );
};

export const SlackBotCreate = (props) => {
  const gifurl =
    'https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/slack/slack_get_token.gif';
  const stillurl =
    'https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/slack/play.jpeg';
  const redirect = useRedirect();
  const { state, dispatch } = useContext(loginState);
  const changeCopiedStat = async () => {
    dispatch({ type: 'isCopied', value: true });
  };
  const alpnum = /^[a-zA-Z0-9_-]+$/;
  const CreateToolbarOf1stStep = (props) => {
    return (
      <StyledToolbar {...props} display="flex" justifyContent="space-between">
        <Button
          style={{
            height: '36px',
            color: '#FFFFFF',
            backgroundColor: '#808080',
          }}
          content="Cancel"
          icon="left arrow"
          labelPosition="left"
          onClick={cancelAction}
        />
        <Button
          style={{
            height: '36px',
            color: '#FFFF99',
            backgroundColor: '#0099FF',
          }}
          content="Next"
          icon="right arrow"
          labelPosition="right"
          onClick={postActionOf1stStep}
        />
      </StyledToolbar>
    );
  };

  const CreateToolbarOf2ndStep = (props) => {
    return (
      <StyledToolbar {...props} display="flex" justifyContent="space-between">
        <Button
          style={{
            height: '36px',
            color: '#FFFFFF',
            backgroundColor: '#808080',
          }}
          content="Back"
          icon="left arrow"
          labelPosition="left"
          onClick={backAction}
        />
        <Button
          style={{
            height: '36px',
            color: '#FFFF99',
            backgroundColor: '#0099FF',
          }}
          content="Next"
          icon="right arrow"
          labelPosition="right"
          onClick={postActionOf2ndStep}
        />
      </StyledToolbar>
    );
  };

  const CreateToolbarOf3rdStep = (props) => {
    return (
      <StyledToolbar {...props} display="flex" justifyContent="space-between">
        <Button
          style={{
            height: '36px',
            color: '#FFFFFF',
            backgroundColor: '#808080',
          }}
          content="Back"
          icon="left arrow"
          labelPosition="left"
          onClick={backAction}
        />
        <Button
          style={{
            height: '36px',
            color: '#FFFF99',
            backgroundColor: '#0099FF',
          }}
          content="Next"
          icon="right arrow"
          labelPosition="right"
          onClick={postActionOf3rdStep}
        />
      </StyledToolbar>
    );
  };

  const CreateToolbarOf4thStep = (props) => {
    return (
      <StyledToolbar {...props} display="flex" justifyContent="space-between">
        <Button
          style={{
            height: '36px',
            color: '#FFFFFF',
            backgroundColor: '#808080',
          }}
          content="Back"
          icon="left arrow"
          labelPosition="left"
          onClick={backAction}
        />
        <SaveButton
          label="SAVE"
          redirect="list"
          submitOnEnter={false}
          style={{ height: '36px' }}
          onClick={saveAction}
        />
      </StyledToolbar>
    );
  };

  const postActionOf1stStep = () => {
    if (state.botName === '') {
      dispatch({ type: 'currentStep', value: 0 });
    } else if (!alpnum.test(state.botName)) {
      dispatch({ type: 'currentStep', value: 0 });
    } else if (state.botKind === undefined) {
      dispatch({ type: 'currentStep', value: 0 });
    } else {
      const bot = UUID.generate();
      dispatch({ type: 'bot', value: bot });
      dispatch({ type: 'isCopied', value: false });
      dispatch({
        type: 'slackManifest',
        value: generateManifest({
          botKind: state.botKind,
          domain: domain,
          user: state.user,
          bot: bot,
          botName: state.botName,
        }),
      });
      dispatch({ type: 'currentStep', value: 1 });
    }
  };

  const postActionOf2ndStep = () => {
    if (state.isCopied === true) {
      dispatch({ type: 'currentStep', value: 2 });
    } else {
      dispatch({ type: 'currentStep', value: 1 });
    }
  };

  const postActionOf3rdStep = () => {
    if (state.slackBotToken === '') {
      dispatch({ type: 'currentStep', value: 2 });
    } else if (state.slackSigningSecret === '') {
      dispatch({ type: 'currentStep', value: 2 });
    } else {
      dispatch({ type: 'currentStep', value: 3 });
    }
  };

  const cancelAction = () => {
    dispatch({ type: 'bot', value: undefined });
    dispatch({ type: 'botName', value: '' });
    dispatch({ type: 'botKind', value: undefined });
    dispatch({ type: 'isCopied', value: false });
    dispatch({ type: 'slackBotToken', value: '' });
    dispatch({ type: 'slackSigningSecret', value: '' });
    redirect('/slackBots');
  };

  const backAction = () => {
    dispatch({ type: 'currentStep', value: 0 });
    dispatch({ type: 'isCopied', value: false });
  };

  const saveAction = () => {
    if (state.slackBotToken === '') {
      dispatch({ type: 'currentStep', value: 2 });
    } else if (state.slackSigningSecret === '') {
      dispatch({ type: 'currentStep', value: 2 });
    } else {
      dispatch({ type: 'bot', value: undefined });
      dispatch({ type: 'botName', value: '' });
      dispatch({ type: 'botKind', value: undefined });
      dispatch({ type: 'currentStep', value: 0 });
      dispatch({ type: 'isCopied', value: false });
      dispatch({ type: 'slackBotToken', value: '' });
      dispatch({ type: 'slackSigningSecret', value: '' });
    }
  };

  const memoryBotName = (e) => {
    let value = e.target.value;
    dispatch({ type: 'botName', value });
  };

  const memoryBotKind = (e) => {
    sessionStorage.setItem('botKind', e);
    let value = sessionStorage.getItem('botKind');
    dispatch({ type: 'botKind', value });
  };

  const memoryBotToken = (e) => {
    let value = e.target.value;
    dispatch({ type: 'slackBotToken', value });
  };

  const memorySigningSecret = (e) => {
    let value = e.target.value;
    dispatch({ type: 'slackSigningSecret', value });
  };

  return (
    <div>
      <Stepper
        steps={[
          { label: '初期項目設定' },
          { label: 'マニフェスト取得' },
          { label: 'トークン設定' },
          { label: 'ボット作成' },
        ]}
        activeStep={state.currentStep}
        connectorStyleConfig={{ stepSize: '50px' }}
        stepClassName={{ width: '10px' }}
        styleConfig={{ size: '50px' }}
        style={{ width: '1000px' }}
      />

      <span style={{ display: state.currentStep === 0 ? '' : 'none' }}>
        <StyledCard width={1000} mt="1em">
          <Create {...props}>
            <SimpleForm toolbar={<CreateToolbarOf1stStep />}>
              {state.botName === '' && (
                <p>
                  <font color="red">必須項目</font>
                </p>
              )}
              {state.botName !== '' && !alpnum.test(state.botName) && (
                <p>
                  <font color="red">半角英数字及び_,-</font>
                </p>
              )}
              <TextInput
                source="name"
                label="ボット名"
                value={state.botName}
                onChange={memoryBotName}
                fullWidth
              />
              {state.botKind === undefined && (
                <p>
                  <font color="red">必須項目</font>
                </p>
              )}
              <ReferenceInput
                source="kindID"
                reference="slackBotKinds"
                onChange={memoryBotKind}
                label="種類"
                fullWidth
              >
                <AutocompleteInput optionText="name" fullWidth />
              </ReferenceInput>
            </SimpleForm>
          </Create>
        </StyledCard>
      </span>
      <span style={{ display: state.currentStep === 1 ? '' : 'none' }}>
        <StyledCard width={1000} mt="1em">
          <Banner delay={1} gradient={false} pauseOnHover speed={100}>
            <Message>
              生成されたマニフェストをコピーし、Slack管理画面にペーストしてボットを作成します。
              ボット作成後、ボットトークン及びサインインシークレットを取得し、
              次ステップの「必須項目」欄に貼り付けてください。
              詳細は、「説明動画」をご確認してください。
            </Message>
          </Banner>
          <Manual gifurl={gifurl} stillurl={stillurl} />
          <Create {...props}>
            <SimpleForm toolbar={<CreateToolbarOf2ndStep />}>
              {!state.isCopied && (
                <p>
                  <font color="red">コピーしてください。</font>
                </p>
              )}
              <ClipbordBox>
                <ClipbordBoxText>{state.slackManifest}</ClipbordBoxText>
                <CopyToClipboard
                  text={state.slackManifest}
                  onCopy={changeCopiedStat}
                >
                  <ClipbordBoxMsg>
                    {state.isCopied ? 'Copied!' : <MdContentCopy />}
                  </ClipbordBoxMsg>
                </CopyToClipboard>
              </ClipbordBox>
            </SimpleForm>
          </Create>
        </StyledCard>
      </span>
      <span style={{ display: state.currentStep === 2 ? '' : 'none' }}>
        <StyledCard width={1000} mt="1em">
          <Create {...props}>
            <SimpleForm toolbar={<CreateToolbarOf3rdStep />}>
              {state.slackBotToken === '' && (
                <p>
                  <font color="red">必須項目</font>
                </p>
              )}
              <TextInput
                source="bot_token"
                label="ボットトークン"
                onChange={memoryBotToken}
                fullWidth
                initialValue={state.slackBotToken}
              />
              {state.slackSigningSecret === '' && (
                <p>
                  <font color="red">必須項目</font>
                </p>
              )}
              <TextInput
                source="signing_secret"
                label="サインインシークレット"
                onChange={memorySigningSecret}
                fullWidth
                initialValue={state.slackSigningSecret}
              />
            </SimpleForm>
          </Create>
        </StyledCard>
      </span>
      <span style={{ display: state.currentStep === 3 ? '' : 'none' }}>
        <StyledCard width={1000} mt="1em">
          <Create {...props}>
            <SimpleForm toolbar={<CreateToolbarOf4thStep />}>
              <TextInput
                source="bot_token"
                disabled
                label="ボットトークン"
                initialValue={state.slackBotToken}
                fullWidth
              />
              <TextInput
                source="signing_secret"
                disabled
                label="サインインシークレット"
                initialValue={state.slackSigningSecret}
                fullWidth
              />
              <TextInput
                source="id"
                label="ID"
                disabled
                validate={required()}
                initialValue={state.bot}
                fullWidth
              />
              <TextInput
                source="name"
                label="ボット名"
                disabled
                validate={required()}
                initialValue={state.botName}
                fullWidth
              />
              <ReferenceInput
                source="kindID"
                reference="slackBotKinds"
                disabled
                validate={required()}
                onChange={memoryBotKind}
                label="種類"
                fullWidth
                initialValue={state.botKind}
              >
                <AutocompleteInput optionText="name" fullWidth />
              </ReferenceInput>
              <TextInput
                source="manifest"
                label="マニフェスト"
                disabled
                initialValue={state.slackManifest}
                fullWidth
                multiline
              />
            </SimpleForm>
          </Create>
        </StyledCard>
      </span>
    </div>
  );
};
