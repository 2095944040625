import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import gfm from 'remark-gfm';

import {
  SlackAdminManual,
  TeamsAdminManual,
  SlackDMUserManual,
  SlackChannelUserManual,
  TeamsUserManual,
  LangSetList,
} from './Contents';

export const Dashboard = () => {
  const [tagNum, setTagNum] = useState('slack');

  const handleChange = (event, newValue) => {
    setTagNum(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={tagNum}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Slack マニュアル" value="slack_manual" />
            <Tab label="Teams マニュアル" value="teams_manual" />
            <Tab label="言語対略語一覧" value="langset" />
          </TabList>
        </Box>

        <TabPanel value="slack_manual">
          <TabContext value={tagNum}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Bot作成マニュアル" value="slack_adm" />
                <Tab label="チャネル版 利用マニュアル" value="slack_channel" />
                <Tab label="DM版 利用マニュアル" value="slack_dm" />
              </TabList>
            </Box>
          </TabContext>
        </TabPanel>
        <TabPanel value="teams_manual">
          <TabContext value={tagNum}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Bot作成マニュアル" value="teams_adm" />
                <Tab label="Webhook版 利用マニュアル" value="teams_webhook" />
              </TabList>
            </Box>
          </TabContext>
        </TabPanel>
        <TabPanel value="teams_webhook" rehypePlugins={[rehypeRaw]}>
          <div>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {TeamsUserManual}
            </ReactMarkdown>
          </div>
        </TabPanel>
        <TabPanel value="slack_adm">
          <div>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {SlackAdminManual}
            </ReactMarkdown>
          </div>
        </TabPanel>
        <TabPanel value="teams_adm">
          <div>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {TeamsAdminManual}
            </ReactMarkdown>
          </div>
        </TabPanel>
        <TabPanel value="slack_channel">
          <div>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {SlackChannelUserManual}
            </ReactMarkdown>
          </div>
        </TabPanel>
        <TabPanel value="slack_dm">
          <div>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {SlackDMUserManual}
            </ReactMarkdown>
          </div>
        </TabPanel>
        <TabPanel value="langset" rehypePlugins={[rehypeRaw]}>
          <div>
            <ReactMarkdown rehypePlugins={[gfm]}>
              {LangSetList}
            </ReactMarkdown>
          </div>
        </TabPanel>
      </TabContext>
    </Box>
  );
};
