import React from 'react';
import { Login } from 'react-admin';
import { LoginForm } from './LoginForm';

export const LoginPage = () => {
  return (
    <Login backgroundImage="https://ki-public-assets.s3.ap-northeast-1.amazonaws.com/logo/ki_minna_image01.png">
      <LoginForm />
    </Login>
  );
};
