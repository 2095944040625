import React from 'react';

const GifPlayer = require('react-gif-player');

class Manual extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <GifPlayer
          gif={this.props.gifurl}
          still={this.props.stillurl}
          pauseRef={(pause) => (this.pauseGif = pause)}
          onTogglePlay={(playing) => this.setState({ playing })}
          // autoplay
        />
        <br />
      </React.Fragment>
    );
  }
}

export default Manual;
