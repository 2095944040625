export const SlackAdminManual = `
# Slack Bot作成マニュアル
### ボット作成開始   
Slackボットメニューを選択し、画面右上の「CREATE」をクリックします。   

<img width="500" alt="" src="https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/slack/image/slack_adm_001.png">

<br>

### 「初期項目設定」画面
下記項目を設定します。   
* ボット名（２バイト文字は使用できません）   
* ボット種別（チャネル半とDM版のどちらかを選択します）   

<img width="500" alt="" src="https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/slack/image/slack_adm_002.png">

<br>

### 「マニフェスト取得」画面   
説明動画の要領でSlackApp画面にてBotを作成します。   

<img width="500" alt="" src="https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/slack/image/slack_adm_003.png">

<br>

生成されたマニフェストをコピーして、SlackApp画面に貼り付けます。  

<img width="500" alt="" src="https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/slack/image/slack_adm_004.png">

<br>

### 「トークン設定」画面     
SlackApp画面にて取得したボットトークンとサインインシークレット入力します。   

<img width="500" alt="" src="https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/slack/image/slack_adm_005.png">

<br>

### 「ボット作成」画面   
最後に内容を確認し、「Save」ボタンをクリックします。   

<img width="500" alt="" src="https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/slack/image/slack_adm_006.png">
`;


export const TeamsAdminManual = `
# Teams Bot作成マニュアル
### ボット作成開始   
Teamsボットメニューを選択し、画面右上の「CREATE」をクリックします。   

<img width="500" alt="" src="https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/slack/image/teams_adm_001.png">

<br>

### 「初期項目設定」画面
* ボット名を入力します。

<img width="500" alt="" src="https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/slack/image/teams_adm_002.png">

<br>

### 「URL取得」画面   
説明動画の要領でTeams Botを作成します。   

<img width="500" alt="" src="https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/slack/image/teams_adm_003.png">

<br>

生成されたURLをコピーして、TeamsBotのコールバックURLに貼り付けます。  

<img width="500" alt="" src="https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/slack/image/teams_adm_004.png">

<br>

### 「トークン設定」画面     
生成さえたセキュリティトークン入力します。   

<img width="500" alt="" src="https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/slack/image/teams_adm_005.png">

<br>

### 「ボット作成」画面   
最後に内容を確認し、「Save」ボタンをクリックします。   

<img width="500" alt="" src="https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/slack/image/teams_adm_006.png">
`;

export const SlackDMUserManual = `
# Slack DM版
翻訳ボットと直接チャットする形式のボットです。  
ボットに対して原文を入力すると、設定された翻訳言語方向にしたがって訳文を返します。

<br>
<br>

## 翻訳言語方向の設定
* 原文を入力する前に翻訳言語方向を設定します。（デフォルトは日英）  
* 翻訳言語方向は、Slackユーザーごとに保持されます。

<br>

#### 翻訳言語方向は、ボットに対して「言語対略語」を送ることで設定します。  
<img width="400" alt="" src="https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/slack/image/bot_slack_dm_img_001.png">

<br>

#### 正常に切り替わった場合は「Set to <言語対略語>」と返信されます。  
<img width="400" alt="" src="https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/slack/image/bot_slack_dm_img_002.png">

<br>

## 翻訳実施
#### メッセージ画面に翻訳原文を入力します。
<img width="400" alt="" src="https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/slack/image/bot_slack_dm_img_003.png">
`;

export const SlackChannelUserManual = `
# Slack チャネル版
公開チャット内でチャットする形式のボットです。  
プライベートチャットやダイレクトメッセージでは呼び出せません。   
事前にボットをチャネルに追加しておくと、設定された翻訳言語方向にしたがって訳文を返します。

<br>
<br>

## チャネルへのアプリ追加
#### 任意のチャネルで作成したBotを呼び出します。  
<img width="400" alt="" src="https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/slack/image/bot_slack_ch_img_001.0.png">

<br>

#### 「チャネルに追加する」をクリックして、チャネルへのアプリ追加を許可します。  
<img width="400" alt="" src="https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/slack/image/bot_slack_ch_img_001.1.png">

<br>
<br>

## 翻訳言語方向の設定
* 原文を入力する前に翻訳言語方向を設定します。（デフォルトは日英）  
* 翻訳言語方向は、Slackユーザーごとに保持されます。

#### 翻訳言語方向は、ボットに対して「言語対略語」を送ることで設定します。  
<img width="400" alt="" src="https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/slack/image/bot_slack_dm_img_001.png">

<br>

#### 正常に切り替わった場合はスレッド側に「Set to <言語対略語>」と返信されます。  
<img width="400" alt="" src="https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/slack/image/bot_slack_ch_img_005.png">

<br>
<br>


## 翻訳実施
#### メッセージ画面に翻訳原文を入力すると、スレッド側に訳文が返信されます。
<img width="400" alt="" src="https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/slack/image/bot_slack_ch_img_006.png">

<br>
<br>

## 翻訳停止
ボットをチャネルから追い出すと、以降のメッセージは翻訳されません。
#### ボット追い出し文字列「By2Bt」（Bye Bye Botの略）を送信します。
<img width="400" alt="" src="https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/slack/image/bot_slack_ch_img_007.png">

<br>

#### 処理が成功した場合はスレッド側に「Bot is gone.」と返信されます。  
<img width="400" alt="" src="https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/slack/image/bot_slack_ch_img_008.png">
`;

export const TeamsUserManual = `
# Teams Webhook版
チーム内でボットにメンションして呼び出す形式のボットです。  
プライベートチャットやダイレクトメッセージでは呼び出せません。

<br>
<br>

## 翻訳実施
ボットに対して下記メッセージを一度に送信する必要があります。  
Bot指定メンション後、最初の行で言語対の略語を指定することで翻訳方向を特定します。  
* ボットへのメンション
* 言語対略語
* 原文
  
#### 任意のチャネルで作成したBotを呼び出します。
<img width="400" alt="" src="https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/teams/image/bot_teams_wh_img_001.png">

<br>
<br>

#### 言語対略語、翻訳原文を入力後、「Enter」で送信します。  
<font color="Red">(一度に送信しないと翻訳できません。)</font>  
<img width="400" alt="" src="https://ki-manual-assets.s3.ap-northeast-1.amazonaws.com/trans-bot/teams/image/bot_teams_wh_img_002.png">
`;

export const LangSetList = `
# 言語対略語一覧


|原文言語|訳文言語 |言語対略語 |
|:--|:--|:--|
|日本語|英語|ja2en|
|日本語|中国語（簡体字）|ja2cn|
|日本語|中国語（繁体字）|ja2tw|
|日本語|韓国語|ja2ko|
|日本語|ドイツ語|ja2de|
|日本語|フランス語|ja2fr|
|日本語|スペイン語|ja2es|
|日本語|ポルトガル語|ja2pt|
|日本語|インドネシア語|ja2id|
|日本語|ミャンマー語|ja2my|
|日本語|タイ語|ja2th|
|日本語|ベトナム語|ja2vi|
|日本語|アラビア語|ja2ar|
|日本語|イタリア語|ja2it|
|日本語|ロシア語|ja2ru|
|日本語|フィリピン語|ja2ph|
|日本語|クメール語|ja2km|
|日本語|モンゴル語|ja2mn|
|日本語|ネパール語|ja2ne|
|英語|中国語（簡体字）|en2cn|
|英語|中国語（繁体字）|en2tw|
|英語|韓国語|en2ko|
|英語|ドイツ語|en2de|
|英語|フランス語|en2fr|
|英語|スペイン語|en2es|
|英語|ポルトガル語|en2pt|
|英語|インドネシア語|en2id|
|英語|ミャンマー語|en2my|
|英語|タイ語|en2th|
|英語|ベトナム語|en2vi|
|英語|アラビア語|en2ar|
|英語|イタリア語|en2it|
|英語|ロシア語|en2ru|
|英語|フィリピン語|en2ph|
|英語|クメール語|en2km|
|英語|モンゴル語|en2mn|
|英語|ネパール語|en2ne|
|英語|日本語|en2ja|
|中国語（簡体字）|日本語|cn2ja|
|中国語（繁体字）|日本語|tw2ja|
|韓国語|日本語|ko2ja|
|ドイツ語|日本語|de2ja|
|フランス語|日本語|fr2ja|
|スペイン語|日本語|es2ja|
|ポルトガル語|日本語|pt2ja|
|インドネシア語|日本語|id2ja|
|ミャンマー語|日本語|my2ja|
|タイ語|日本語|th2ja|
|ベトナム語|日本語|vi2ja|
|アラビア語|日本語|ar2ja|
|イタリア語|日本語|it2ja|
|ロシア語|日本語|ru2ja|
|フィリピン語|日本語|ph2ja|
|クメール語|日本語|km2ja|
|モンゴル語|日本語|mn2ja|
|ネパール語|日本語|ne2ja|
|中国語（簡体字）|英語|cn2en|
|中国語（繁体字）|英語|tw2en|
|韓国語|英語|ko2en|
|ドイツ語|英語|de2en|
|フランス語|英語|fr2en|
|スペイン語|英語|es2en|
|ポルトガル語|英語|pt2en|
|インドネシア語|英語|id2en|
|ミャンマー語|英語|my2en|
|タイ語|英語|th2en|
|ベトナム語|英語|vi2en|
|アラビア語|英語|ar2en|
|イタリア語|英語|it2en|
|ロシア語|英語|ru2en|
|フィリピン語|英語|ph2en|
|クメール語|英語|km2en|
|モンゴル語|英語|mn2en|
|ネパール語|英語|ne2en|

`;
