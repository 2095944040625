import React from 'react';
import { SimpleShowLayout, TextField } from 'react-admin';

const DeleteConfirmTitle = '削除しますか?';

const DeleteConfirmContent = (props) => {
  return (
    <SimpleShowLayout {...props}>
      <TextField source="name" labal="ボット名" />
    </SimpleShowLayout>
  );
};

export { DeleteConfirmTitle, DeleteConfirmContent };
