import { Auth } from '@aws-amplify/auth';
import { Amplify } from '@aws-amplify/core';
import React, { useReducer } from 'react';
import { Admin, Resource, defaultTheme } from 'react-admin';
import {
  buildAuthProvider,
  buildDataProvider,
  CognitoGroupList,
  CognitoUserList,
  CognitoUserShow,
} from 'react-admin-amplify';
import awsExports from './aws-exports';
import {
  SlackBotCreate,
  SlackBotEdit,
  SlackBotList,
  SlackBotShow,
} from './components/SlackBot';
import {
  TeamsBotCreate,
  TeamsBotEdit,
  TeamsBotList,
  TeamsBotShow,
} from './components/TeamsBot';
import {
  SlackBotKindCreate,
  SlackBotKindEdit,
  SlackBotKindList,
  SlackBotKindShow,
} from './components/SlackBotKind';
import {
  TeamsBotKindCreate,
  TeamsBotKindEdit,
  TeamsBotKindList,
  TeamsBotKindShow,
} from './components/TeamsBotKind';
import CustomLayout from './components/Layout';
import { Dashboard } from './components/Dashboard';
import { LoginPage } from './components/LoginPage';
import * as mutations from './graphql/mutations';
import * as queries from './graphql/queries';
import { createTheme } from '@material-ui/core/styles';
export const loginState = React.createContext();
const initialState = {
  user: '',
  group: '',
  currentStep: 0,
  bot: undefined,
  botName: '',
  botKind: undefined,
  slackManifest: '',
  teamsUrl: undefined,
  isCopied: false,
  teamsSecurityToken: '',
  slackBotToken: '',
  slackSigningSecret: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'user':
      return { ...state, user: action.value };
    case 'group':
      return { ...state, group: action.value };
    case 'currentStep':
      return { ...state, currentStep: action.value };
    case 'bot':
      return { ...state, bot: action.value };
    case 'botName':
      return { ...state, botName: action.value };
    case 'botKind':
      return { ...state, botKind: action.value };
    case 'slackManifest':
      return { ...state, slackManifest: action.value };
    case 'teamsUrl':
      return { ...state, teamsUrl: action.value };
    case 'isCopied':
      return { ...state, isCopied: action.value };
    case 'teamsSecurityToken':
      return { ...state, teamsSecurityToken: action.value };
    case 'slackBotToken':
      return { ...state, slackBotToken: action.value };
    case 'slackSigningSecret':
      return { ...state, slackSigningSecret: action.value };
    default:
      return state;
  }
};

// import { useGetIdentity, useGetOne } from 'react-admin';

Amplify.configure(awsExports);

const authProvider = buildAuthProvider({
  authGroups: ['superadmin', 'admin', 'teams', 'slack'],
});

const dataProvider = buildDataProvider(
  {
    queries,
    mutations,
  },
  {
    storageBucket: awsExports.aws_user_files_s3_bucket,
    storageRegion: awsExports.aws_user_files_s3_bucket_region,
    enableAdminQueries: true,
  }
);

const customTheme = createTheme({
  ...defaultTheme,
  ...{
    palette: {
      secondary: {
        main: '#129D9F',
      },
    },
  },
});

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  authProvider.getIdentity = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const groups =
        user.signInUserSession.accessToken.payload['cognito:groups'];
      dispatch({ type: 'user', value: user['username'] });
      dispatch({ type: 'group', value: groups[0] });

      return {
        id: user['username'],
        fullName: user['username'],
        avatar: '',
      };
    } catch (e) {
      console.log(e);
    }
  };
  function slack_acl(group) {
    const slack = ['admin', 'slack'];
    return slack.includes(group[0]) || slack.includes(group[1]);
  }
  function teams_acl(group) {
    const teams = ['admin', 'teams'];
    return teams.includes(group[0]) || teams.includes(group[1]);
  }

  return (
    <loginState.Provider value={{ state, dispatch }}>
      <Admin
        layout={CustomLayout}
        theme={customTheme}
        authProvider={authProvider}
        dataProvider={dataProvider}
        loginPage={LoginPage}
        dashboard={Dashboard}
      >
        {(permissions) => [
          slack_acl(permissions) ? (
            <Resource
              name="slackBots"
              options={{ label: 'Slackボット' }}
              list={SlackBotList}
              show={SlackBotShow}
              edit={SlackBotEdit}
              create={SlackBotCreate}
            />
          ) : null,
          teams_acl(permissions) ? (
            <Resource
              name="teamsBots"
              options={{ label: 'Teamsボット' }}
              list={TeamsBotList}
              show={TeamsBotShow}
              edit={TeamsBotEdit}
              create={TeamsBotCreate}
            />
          ) : null,
          <Resource
            name="slackBotKinds"
            options={{ label: 'Slackボットタイプ' }}
            list={permissions.includes('admin') ? SlackBotKindList : null}
            show={permissions.includes('admin') ? SlackBotKindShow : null}
            edit={permissions.includes('admin') ? SlackBotKindEdit : null}
            create={permissions.includes('admin') ? SlackBotKindCreate : null}
          />,
          <Resource
            name="teamsBotKinds"
            options={{ label: 'Teamsボットタイプ' }}
            list={permissions.includes('admin') ? TeamsBotKindList : null}
            show={permissions.includes('admin') ? TeamsBotKindShow : null}
            edit={permissions.includes('admin') ? TeamsBotKindEdit : null}
            create={permissions.includes('admin') ? TeamsBotKindCreate : null}
          />,
          permissions.includes('superadmin') ? (
            <Resource
              name="cognitoUsers"
              options={{ label: 'ユーザー' }}
              list={CognitoUserList}
              show={CognitoUserShow}
            />
          ) : null,
          permissions.includes('superadmin') ? (
            <Resource
              name="cognitoGroups"
              options={{ label: 'グループ' }}
              list={CognitoGroupList}
            />
          ) : null,
        ]}
      </Admin>
    </loginState.Provider>
  );
}

export default App;
