/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTeamsBotKind = /* GraphQL */ `
  mutation CreateTeamsBotKind(
    $input: CreateTeamsBotKindInput!
    $condition: ModelTeamsBotKindConditionInput
  ) {
    createTeamsBotKind(input: $input, condition: $condition) {
      id
      name
      bots {
        items {
          id
          name
          owner
          url
          security_token
          kindID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTeamsBotKind = /* GraphQL */ `
  mutation UpdateTeamsBotKind(
    $input: UpdateTeamsBotKindInput!
    $condition: ModelTeamsBotKindConditionInput
  ) {
    updateTeamsBotKind(input: $input, condition: $condition) {
      id
      name
      bots {
        items {
          id
          name
          owner
          url
          security_token
          kindID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeamsBotKind = /* GraphQL */ `
  mutation DeleteTeamsBotKind(
    $input: DeleteTeamsBotKindInput!
    $condition: ModelTeamsBotKindConditionInput
  ) {
    deleteTeamsBotKind(input: $input, condition: $condition) {
      id
      name
      bots {
        items {
          id
          name
          owner
          url
          security_token
          kindID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSlackBotKind = /* GraphQL */ `
  mutation CreateSlackBotKind(
    $input: CreateSlackBotKindInput!
    $condition: ModelSlackBotKindConditionInput
  ) {
    createSlackBotKind(input: $input, condition: $condition) {
      id
      name
      bots {
        items {
          id
          name
          owner
          manifest
          bot_token
          signing_secret
          kindID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSlackBotKind = /* GraphQL */ `
  mutation UpdateSlackBotKind(
    $input: UpdateSlackBotKindInput!
    $condition: ModelSlackBotKindConditionInput
  ) {
    updateSlackBotKind(input: $input, condition: $condition) {
      id
      name
      bots {
        items {
          id
          name
          owner
          manifest
          bot_token
          signing_secret
          kindID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSlackBotKind = /* GraphQL */ `
  mutation DeleteSlackBotKind(
    $input: DeleteSlackBotKindInput!
    $condition: ModelSlackBotKindConditionInput
  ) {
    deleteSlackBotKind(input: $input, condition: $condition) {
      id
      name
      bots {
        items {
          id
          name
          owner
          manifest
          bot_token
          signing_secret
          kindID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTeamsBot = /* GraphQL */ `
  mutation CreateTeamsBot(
    $input: CreateTeamsBotInput!
    $condition: ModelTeamsBotConditionInput
  ) {
    createTeamsBot(input: $input, condition: $condition) {
      id
      name
      owner
      url
      security_token
      kindID
      createdAt
      updatedAt
    }
  }
`;
export const updateTeamsBot = /* GraphQL */ `
  mutation UpdateTeamsBot(
    $input: UpdateTeamsBotInput!
    $condition: ModelTeamsBotConditionInput
  ) {
    updateTeamsBot(input: $input, condition: $condition) {
      id
      name
      owner
      url
      security_token
      kindID
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeamsBot = /* GraphQL */ `
  mutation DeleteTeamsBot(
    $input: DeleteTeamsBotInput!
    $condition: ModelTeamsBotConditionInput
  ) {
    deleteTeamsBot(input: $input, condition: $condition) {
      id
      name
      owner
      url
      security_token
      kindID
      createdAt
      updatedAt
    }
  }
`;
export const createSlackBot = /* GraphQL */ `
  mutation CreateSlackBot(
    $input: CreateSlackBotInput!
    $condition: ModelSlackBotConditionInput
  ) {
    createSlackBot(input: $input, condition: $condition) {
      id
      name
      owner
      manifest
      bot_token
      signing_secret
      kindID
      createdAt
      updatedAt
    }
  }
`;
export const updateSlackBot = /* GraphQL */ `
  mutation UpdateSlackBot(
    $input: UpdateSlackBotInput!
    $condition: ModelSlackBotConditionInput
  ) {
    updateSlackBot(input: $input, condition: $condition) {
      id
      name
      owner
      manifest
      bot_token
      signing_secret
      kindID
      createdAt
      updatedAt
    }
  }
`;
export const deleteSlackBot = /* GraphQL */ `
  mutation DeleteSlackBot(
    $input: DeleteSlackBotInput!
    $condition: ModelSlackBotConditionInput
  ) {
    deleteSlackBot(input: $input, condition: $condition) {
      id
      name
      owner
      manifest
      bot_token
      signing_secret
      kindID
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      password
      picture {
        bucket
        region
        key
      }
      documents {
        bucket
        region
        key
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      password
      picture {
        bucket
        region
        key
      }
      documents {
        bucket
        region
        key
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      password
      picture {
        bucket
        region
        key
      }
      documents {
        bucket
        region
        key
      }
      createdAt
      updatedAt
    }
  }
`;
