import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import HomeIcon from '@material-ui/icons/Home';
import { useGetResourceLabel, getResources } from 'ra-core';

const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources, shallowEqual);
  const getResourceLabel = useGetResourceLabel();
  return (
    <div>
      <MenuItemLink
        to="/"
        primaryText="ホーム"
        leftIcon={<HomeIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {resources.filter(r => r.hasList).map((resource) => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={getResourceLabel(resource.name, 2)}
          leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
      {isXSmall && logout}
    </div>
  );
};

export default Menu;
