import React, { useState, Fragment } from 'react';
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  useShowController,
  required,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  ListButton,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import AlertError from '@material-ui/icons/ErrorOutline';
import { AmplifyFilter } from 'react-admin-amplify';
import styled from 'styled-components';
import { color, typography, space, layout, flexbox } from 'styled-system';
import DeleteWithCustomConfirmButton from 'ra-delete-with-custom-confirm-button';
import { DeleteConfirmTitle, DeleteConfirmContent } from './DeleteConfirm';

const defaultQuery = 'listSlackBotKinds';
const validateName = [required()];
const StyledCard = styled(Card)`
  ${color}
  ${typography}
  ${space}
  ${layout}
  ${flexbox}
`;

const StyledToolbar = styled(Toolbar)`
  ${color}
  ${typography}
  ${space}
  ${layout}
  ${flexbox}
`;

const BotFilter = (props) => (
  <AmplifyFilter {...props} defaultQuery={defaultQuery}>
    <TextInput
      source="slackBotKindByName.name"
      label="Name"
      alwaysOn
      resettable
    />
  </AmplifyFilter>
);

const PostBulkActionButtons = () => <Fragment></Fragment>;

export const TeamsBotKindList = (props) => {
  const [query, setQuery] = useState(defaultQuery);

  return (
    <List
      {...props}
      exporter={false}
      filters={<BotFilter setQuery={setQuery} />}
      bulkActionButtons={<PostBulkActionButtons />}
    >
      <Datagrid>
        <TextField
          source="id"
          sortBy={query}
          sortable={query === 'TeamsBotKindByName'}
        />
        <TextField source="name" sortable={false} />
        <DateField source="createdAt" sortable={false} />
        <DateField source="updatedAt" sortable={false} />
        <ShowButton />
        <DeleteWithCustomConfirmButton
          title={DeleteConfirmTitle}
          content={DeleteConfirmContent}
        />
      </Datagrid>
    </List>
  );
};

export const TeamsBotKindShow = (props) => {
  const ShowToolbar = (props) => {
    return (
      <StyledToolbar {...props} display="flex" justifyContent="space-between">
        <ListButton basePath={basePath} label="Cancel" icon={<AlertError />} />
      </StyledToolbar>
    );
  };
  const { resource, record, redirect, save, basePath, version } =
    useShowController(props);

  return (
    <div className="show-page">
      <StyledCard width={1000} mt="1em">
        {record && (
          <SimpleForm
            basePath={basePath}
            redirect={redirect}
            resource={resource}
            record={record}
            save={save}
            toolbar={<ShowToolbar />}
            version={version}
          >
            <TextField source="id" fullWidth />
            <TextField source="name" fullWidth />
            <DateField source="createdAt" fullWidth />
            <DateField source="updatedAt" fullWidth />
          </SimpleForm>
        )}
      </StyledCard>
    </div>
  );
};

export const TeamsBotKindEdit = (props) => (
  <Edit
    {...props}
    transform={(data) => {
      const { ...rest } = data;
      return rest;
    }}
  >
    <SimpleForm redirect="list">
      <TextInput source="id" disabled />
      <TextInput source="name" validate={validateName} />
    </SimpleForm>
  </Edit>
);

export const TeamsBotKindCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="id" validate={validateName} />
      <TextInput source="name" validate={validateName} />
    </SimpleForm>
  </Create>
);
