import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import styled from 'styled-components';

const StyledAppBar = styled(AppBar)`
  height: 50px;
`;

const CustomToolbar = styled(Toolbar)`
  min-height: 30px;
  maxwidth: '40px';
  marginleft: -35;
`;

const CustomTypography = styled(Typography)`
  flex: 1;
  textoverflow: 'ellipsis';
  whitespace: 'nowrap';
  overflow: 'hidden';
  marginleft: -10;
`;

const CustomAppBar = (props) => {
  return (
    <StyledAppBar {...props} color="secondary">
      <CustomToolbar >
        <img src={`${process.env.PUBLIC_URL}/ki_logo02.png`} alt="ki_logo" />
      </CustomToolbar>
      <CustomTypography variant="h6" color="inherit">
        みんなの自動翻訳@KI
      </CustomTypography>
    </StyledAppBar>
  );
};

export default CustomAppBar;
