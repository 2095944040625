/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTeamsBotKind = /* GraphQL */ `
  query GetTeamsBotKind($id: ID!) {
    getTeamsBotKind(id: $id) {
      id
      name
      bots {
        items {
          id
          name
          owner
          url
          security_token
          kindID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTeamsBotKinds = /* GraphQL */ `
  query ListTeamsBotKinds(
    $id: ID
    $filter: ModelTeamsBotKindFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTeamsBotKinds(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        bots {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamsBotKindByName = /* GraphQL */ `
  query TeamsBotKindByName(
    $name: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamsBotKindFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamsBotKindByName(
      name: $name
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        bots {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSlackBotKind = /* GraphQL */ `
  query GetSlackBotKind($id: ID!) {
    getSlackBotKind(id: $id) {
      id
      name
      bots {
        items {
          id
          name
          owner
          manifest
          bot_token
          signing_secret
          kindID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSlackBotKinds = /* GraphQL */ `
  query ListSlackBotKinds(
    $id: ID
    $filter: ModelSlackBotKindFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSlackBotKinds(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        bots {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const slackBotKindByName = /* GraphQL */ `
  query SlackBotKindByName(
    $name: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSlackBotKindFilterInput
    $limit: Int
    $nextToken: String
  ) {
    slackBotKindByName(
      name: $name
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        bots {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeamsBot = /* GraphQL */ `
  query GetTeamsBot($id: ID!) {
    getTeamsBot(id: $id) {
      id
      name
      owner
      url
      security_token
      kindID
      createdAt
      updatedAt
    }
  }
`;
export const listTeamsBots = /* GraphQL */ `
  query ListTeamsBots(
    $id: ID
    $filter: ModelTeamsBotFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTeamsBots(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        owner
        url
        security_token
        kindID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamsBotByName = /* GraphQL */ `
  query TeamsBotByName(
    $name: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamsBotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamsBotByName(
      name: $name
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        owner
        url
        security_token
        kindID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSlackBot = /* GraphQL */ `
  query GetSlackBot($id: ID!) {
    getSlackBot(id: $id) {
      id
      name
      owner
      manifest
      bot_token
      signing_secret
      kindID
      createdAt
      updatedAt
    }
  }
`;
export const listSlackBots = /* GraphQL */ `
  query ListSlackBots(
    $id: ID
    $filter: ModelSlackBotFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSlackBots(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        owner
        manifest
        bot_token
        signing_secret
        kindID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const slackBotByName = /* GraphQL */ `
  query SlackBotByName(
    $name: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSlackBotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    slackBotByName(
      name: $name
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        owner
        manifest
        bot_token
        signing_secret
        kindID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      password
      picture {
        bucket
        region
        key
      }
      documents {
        bucket
        region
        key
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        password
        picture {
          bucket
          region
          key
        }
        documents {
          bucket
          region
          key
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
